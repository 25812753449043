// --- Vendors ---
import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';
import GLightbox from 'glightbox';

// --- Components ---
const home = {
    readyFn: function () {
        jQueryBridget('masonry', Masonry, $);

        console.log("Home");

        const galerie = $('.grid-image').masonry({
            // options
            itemSelector: '.grid-item',
            columnWidth: '.grid-item',
            percentPosition: true,
            horizontalOrder: true
        });

        const lightbox = GLightbox({
            loop: true,
        });

    }
}
export { home };