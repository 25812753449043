// --- Vendors ---

// --- Components ---
const quizz = {
    readyFn: function () {
        console.log("Quizz");

        const questions = window.quizData;
        let currentQuestionIndex = 0;
        const scores = {
            louis: 0,
            niels: 0,
            arnaud: 0,
            rioky: 0,
            thomas: 0
        };

        const container = document.getElementById('questions-container');
        const scoresContainer = document.getElementById('scores-container');
        const nextButton = document.getElementById('next-question');

        // Fonction pour afficher la question
        function showQuestion(index) {
            if (index >= questions.length) {
                showResult();
                return;
            }

            const question = questions[index];
            let html = `<div class="cell-question cell">
                <p class="titre">${question.titre}</p>
                <fieldset>`;

            // Liste des libellés pour les options
            const labels = ["Oui", "Sûrement", "Je ne sais pas", "Probablement pas", "Non"];

            // Création d'un tableau des joueurs à afficher en fonction des ACF
            const players = ["louis", "rioky", "arnaud", "niels", "thomas"];
            const availablePlayers = players.filter(player => {
                // Vérifie si la valeur du champ ACF pour le joueur correspond à la question
                return question[player];  // 'question[player]' correspond au champ ACF du joueur
            });

            // Génération des options
            labels.forEach((label) => {
                html += `
                <div>
                    <input 
                        type="radio" 
                        name="question_${index}" 
                        value="${label.toLowerCase()}" 
                        data-values='${JSON.stringify(availablePlayers)}' 
                        id="${label.toLowerCase()}_${index}" 
                    />
                    <label for="${label.toLowerCase()}_${index}">${label}</label>
                </div>`;
            });

            html += `</fieldset></div>`;
            container.innerHTML = html;
            updateScoresDisplay(); // Met à jour l'affichage des scores à chaque question
        }

        // Fonction pour calculer le score
        function calculateScore(values) {
            // Parcours des réponses de la question
            const selectedAnswer = document.querySelector(`input[name="question_${currentQuestionIndex}"]:checked`);

            if (selectedAnswer) {
                const answerValue = selectedAnswer.value;  // Valeur de la réponse (oui, sûrement, etc.)

                // Vérifier si la réponse correspond à l'une des réponses des membres
                Object.keys(values).forEach(player => {
                    const playerValues = values[player];  // Récupère les réponses pour le joueur

                    if (playerValues.includes(answerValue)) {
                        if (answerValue !== "jsp") {  // "jsp" ne donne pas de points
                            scores[player] += 2;  // Ajouter 2 points si la réponse est correcte
                        }
                    }
                });
            }
        }


        // Fonction pour mettre à jour l'affichage des scores
        function updateScoresDisplay() {
            const scoresHtml = Object.entries(scores)
                .map(([key, value]) => `<p>${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}</p>`)
                .join('');
            scoresContainer.innerHTML = `<div class="scores">${scoresHtml}</div>`;
        }

        // Fonction pour afficher le résultat final
        function showResult() {
            const result = Object.keys(scores).reduce((a, b) => (scores[a] > scores[b] ? a : b));

            // Afficher le message de résultat
            container.innerHTML = `<p>Tu es ${result.charAt(0).toUpperCase() + result.slice(1)} !</p>`;

            scoresContainer.style.display = 'none';  // Cacher le conteneur des scores
            nextButton.style.display = 'none';  // Cacher le bouton "Next"

            $(".cell-container").addClass("fini");
            $(".cell-container .wrapper-image").addClass(result);
        }

        // Écouter l'événement de clic sur le bouton "Next"
        nextButton.addEventListener('click', () => {
            const selectedAnswer = document.querySelector(`input[name="question_${currentQuestionIndex}"]:checked`);

            if (!selectedAnswer) {
                alert("Veuillez répondre à la question avant de continuer.");  // Alerte si aucune réponse n'est sélectionnée
                return;
            }

            // Avant de passer à la question suivante, on calcule le score pour la question courante
            const values = questions[currentQuestionIndex].values; // Récupérer les valeurs de la question courante
            calculateScore(values); // Passer les valeurs à la fonction de calcul
            currentQuestionIndex++;  // Incrémenter l'index de la question

            // Afficher la prochaine question
            if (currentQuestionIndex < questions.length) {
                showQuestion(currentQuestionIndex);
            } else {
                showResult();  // Si c'est la dernière question, afficher le résultat
            }
        });


        // Afficher la première question au chargement
        showQuestion(currentQuestionIndex);
    }
}

export { quizz };
