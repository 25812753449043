/* --- UTILISATION ---
	import {HeaderClass} from './components/_header';

	// INIT HEADER
    let header = new HeaderClass();
    header.initHeader();
*/


export default class HeaderClass {
	// constructor
	constructor(isForceScrolled = false) {
		this.windowScroll = 0;
		this.header = document.getElementById('header');
		this.isScrolled = false;
		this.isForceScrolled = isForceScrolled;
	}

	initHeader() {
		let self = this;

		if(self.header != null){	
			self.headerScroll($(window).scrollTop());
	
			if (self.isForceScrolled) {
				self.header.classList.add('forced');
			}
	
			// HEADER ON SCROLL
			$(window).on('scroll', function () {
				self.headerScroll($(window).scrollTop());
			});
	
			// SWITCH MENU MOBILE
			let dragging = false;
			$("body").on("touchmove", function () {
				dragging = true;
			}).on("touchstart", function () {
				dragging = false;
			});
	
	
			$('#header .navbar-toggle').on('touchend, click', function (e) {
				if (dragging) return;
	
				self.switchMenu();
	
				e.preventDefault();
			});

			if($('body').hasClass('xxlarge')){
				var headerMenu = new Foundation.DropdownMenu($('#menu-menu-principal'));
			}else{
				$('#header-menu').attr('data-submenu-toggle', 'true');
				var headerMenu = new Foundation.AccordionMenu($('#menu-menu-principal'));
			}


			$('#menu-menu-principal .sub-menu .menu-item.current-menu-item').each(function () {
				$(this).parents('.menu-item.is-dropdown-submenu-parent').addClass('current-menu-parent');
			});
		}
	}

	switchMenu() {
		if (document.getElementById('header').classList.contains('open')) {

			this.closeMenu(false);

		} else {

			this.openMenu();

		}

		return false;
	}

	openMenu() {
		document.getElementById('header').classList.add('open');

		// Attend la fin des animations d'apparition du menu
		setTimeout(function () {
			document.getElementById('header').classList.add('is-open');
		}, 250);
	}

	closeMenu() {
		this.header.classList.remove('open', 'is-open');
	}

	addScrolled() {
		this.header.classList.add('scrolled');
		this.isScrolled = true;
	}

	removeScrolled() {
		this.header.classList.remove('scrolled');
		this.isScrolled = false;
	}

	headerScroll(windowScroll) {
		var self = this;
		let headerHeight = self.header.offsetHeight;

		if (Math.abs(windowScroll) >= headerHeight) {

			if (!self.isScrolled) {
				self.addScrolled();
			}

		} else {

			if (self.isScrolled) {
				self.removeScrolled();
			}

			if (self.isScrolled && !self.isForceScrolled) {
				self.removeScrolled();
			}

			if (self.isForceScrolled) {
				self.addScrolled();
			}

		}
	}
}